<template>
  <section class="admin-claims">
    <v-container class="custom-container">
      <div class="textbox">
        <div class="left">
          <h1>{{ $t('langkey.claims') }}</h1>
          <p class="description">
            {{ $t('langkey.admin-claims-description') }}
          </p>
        </div>
        <div class="right">
          <div
            v-click-outside="hideSearchBar"
            class="search-wrapper"
          >
            <v-btn
              v-if="!search"
              icon
              plain
              class="search__icon"
              @click="onSearch"
            >
              <icon-search size="18" />
            </v-btn>
            <v-btn
              v-else
              icon
              plain
              class="clear__icon"
              @click="clearSearch"
            >
              <icon-close color="var(--theme-gray-4)" />
            </v-btn>
            <input
              v-if="showSearchBar"
              v-model="search"
              type="search"
              class="search-primary"
              :placeholder="`${$t('langkey.search')}`"
            >
          </div>
        </div>
      </div>

      <v-card
        rounded="sm"
        class="claims"
      >
        <v-data-table
          class="primary-style"
          :headers="tableFieldClaims"
          :items="[]"
          :search="search"
          hide-default-footer
          disable-sort
        >
          <template
            v-for="(field, index) in tableFieldClaims"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
          </template>
        </v-data-table>
        <!-- langkey.broken
        langkey.not-arrived
        langkey.wrong-order
        langkey.missing -->
      </v-card>
    </v-container>
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/admin-claims.scss";
</style>

<script>
import { tableFieldClaims } from './configs.js';

export default {
  data () {
    return {
      search: '',
      showSearchBar: false,
    };
  },
  computed: {
    tableFieldClaims() {
      return tableFieldClaims;
    },
  },
  methods: {
    onSearch() {
      if (this.search === '') {
        this.showSearchBar = !this.showSearchBar;
      }
    },
    hideSearchBar() {
      if (this.search === '') {
        this.showSearchBar = false;
      }
    },
    clearSearch() {
      this.search = '';
    },
  },
};
</script>